import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTrash , FaChevronDown , FaChevronUp } from "react-icons/fa";
import Modal from "react-modal";
import { API_URL } from "../constants";

// Appliquer les styles du modal
Modal.setAppElement("#root");

export default function Home(props) {
  const [boitiersList, setBoitiersList] = useState([]);
  const [filteredBoitiersList, setFilteredBoitiersList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filterClient, setFilterClient] = useState("Tous les clients"); 
  const [filterLocation, setFilterLocation] = useState("Toutes les localisations"); 
  const [clients, setClients] = useState([]);
  const [locations, setLocations] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [boitierToDelete, setBoitierToDelete] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;
  const admin = user.admin;
  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const openModal = (boitier) => {
    setBoitierToDelete(boitier);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setBoitierToDelete(null);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`${API_URL}/boitier/delete`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: {
          id: boitierToDelete.id,
        },
      });

      if (response.status === 204) {
        console.log("Moteur deleted successfully");
        closeModal();
        window.location.reload();
      } else {
        console.log("Failed to delete moteur. Status:", response.status);
      }
    } catch (error) {
      console.log("Error deleting moteur:", error.message);
    }
  };
  

  useEffect(() => {
    // Appel API pour récupérer la liste des boîtiers
    const fetchBoitiers = async () => {
      try {
        const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const boitiers = response.data.data;
  
        const formattedBoitiers = boitiers.map((boitier) => {
  
          return {
            id: boitier._id,
            name: boitier.nom_boitier,
            dateOfService : boitier.dateMiseEnService,
            alertCount: boitier.dateDepassement ? boitier.dateDepassement.length : 0,
            clientName: boitier.client,
            location: boitier.localisation,
          };
        });
  
        console.log(formattedBoitiers);
        setBoitiersList(formattedBoitiers);  // Stocker la liste complète
  
        // Extraire les clients et localisations uniques
        const uniqueClients = ["Tous les clients", ...new Set(boitiers.map(boitier => boitier.client))];
        const uniqueLocations = ["Toutes les localisations", ...new Set(boitiers.map(boitier => boitier.localisation))];
  
        setClients(uniqueClients);
        setLocations(uniqueLocations);
      } catch (error) {
        console.error("Erreur lors de la récupération des boîtiers:", error);
      }
    };
  
    fetchBoitiers();
  }, [user.userId, user.token]);
  

  useEffect(() => {
    let filteredList = boitiersList;  // Utilise boitiersList pour le filtrage
  
    if (searchName) {
      filteredList = filteredList.filter((boitier) =>
        boitier.name && boitier.name.toLowerCase().includes(searchName.toLowerCase())
      );
    }
  
    if (filterClient && filterClient !== "Tous les clients") {
      filteredList = filteredList.filter((boitier) =>
        boitier.clientName && boitier.clientName.toLowerCase().includes(filterClient.toLowerCase())
      );
    }
  
    if (filterLocation && filterLocation !== "Toutes les localisations") {
      filteredList = filteredList.filter((boitier) =>
        boitier.location && boitier.location.toLowerCase().includes(filterLocation.toLowerCase())
      );
    }
  
    // Trier la liste par le nombre d'alertes (alertCount) en ordre décroissant
    filteredList.sort((a, b) => b.alertCount - a.alertCount);
  
    setFilteredBoitiersList(filteredList);
  }, [searchName, filterClient, filterLocation, boitiersList]);

  useEffect(() => {
    // Une fois que tu récupères les clients et les localisations, assure-toi que les options "Tous les clients"
    // et "Toutes les localisations" sont toujours disponibles dans la liste déroulante.
    if (clients.length > 0 && !clients.includes("Tous les clients")) {
      setClients(prev => ["Tous les clients", ...prev]);
    }
    if (locations.length > 0 && !locations.includes("Toutes les localisations")) {
      setLocations(prev => ["Toutes les localisations", ...prev]);
    }
  }, [clients, locations]);
  
  
  const navigateToDetails = (boitier) => {
    navigate("/details", { state: { boitier } });
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full justify-center">
        <div className="p-4 h-fit flex flex-col items-center">
          <h1 className="text-bleuSTB mb-4 font-bold text-3xl xl:text-5xl 3xl:text-6xl">
            Liste des compteurs
          </h1>
          <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
            <div className="flex-1 lg:mr-4">
              <input
                type="text"
                placeholder="Recherche par nom du compteur"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="mt-4 mb-4 shadow-md w-70 sm:w-96 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base sm:text-lg xl:text-xl border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6"
              />
            </div>
            <div className="lg:ml-4 lg:mr-4 mb-4 lg:mb-0 shadow-md w-52 sm:w-72 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
              <select
                value={filterClient}
                onChange={(e) => setFilterClient(e.target.value)}
                className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
              >
                {clients.map(client => (
                  <option key={client} value={client}>
                    {client}
                  </option>
                ))}
              </select>
            </div>
            <div className="lg:ml-4 shadow-md w-52 sm:w-72 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
              <select
                value={filterLocation}
                onChange={(e) => setFilterLocation(e.target.value)}
                className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
              >
                {locations.map(location => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="hidden lg:block w-full overflow-x-auto">
            <table className="cursor-pointer text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl">
              <thead className="hidden lg:contents">
                <tr>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Nom du compteur
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Date de mise en service
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Nom du client
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Localisation
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Alertes
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredBoitiersList.map((boitier, index) => (
                  <tr
                    className={`flex flex-col mb-10 ${
                      index === hoveredRowIndex ? "bg-hoverTable" : ""
                    } lg:table-row`}
                    key={boitier.id}
                    onClick={() => {
                      navigateToDetails(boitier);
                    }}
                    onMouseEnter={() => handleRowHover(index)}
                    onMouseLeave={() => handleRowHover(-1)}
                  >
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center text-marronSTB font-bold">
                      {boitier.name} 
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      {moment(boitier.dateOfService).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      {boitier.clientName}
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      {boitier.location}
                    </td>
                    <td
                      className={`text-end border-2 border-bleuCielSTB p-4 lg:text-center ${
                        boitier.alertCount > 0
                          ? "bg-red-200 text-red-700"
                          : "bg-green-200 text-green-800"
                      }`}
                    >
                      {boitier.alertCount > 0
                        ? `${boitier.alertCount} dépassement(s)`
                        : "Pas de dépassement"}
                    </td>
                    {admin && (
                      <td className="text-center border-2 border-bleuCielSTB p-4 lg:text-center">
                        <FaTrash
                          className="text-bleuSTB hover:text-marronSTB text-3xl cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(boitier);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
  
            {/* Modal de suppression */}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Confirmation de suppression"
              className="fixed inset-0 flex items-center justify-center p-4"
              overlayClassName="fixed inset-0 bg-black bg-opacity-60"
            >
              <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md mx-auto">
                <h2 className="text-2xl font-semibold mb-5 text-bleuSTB">Confirmation</h2>
                <p className="text-gray-700 mb-6">Êtes-vous sûr de vouloir supprimer ce compteur ?</p>
                <div className="flex gap-4 justify-end">
                  <button
                    onClick={confirmDelete}
                    className="bg-orangeALF text-white px-5 py-3 rounded-lg hover:bg-orange-500"
                  >
                    Oui
                  </button>
                  <button
                    onClick={closeModal}
                    className="bg-gray-200 text-gray-800 px-5 py-3 rounded-lg hover:bg-gray-300"
                  >
                    Non
                  </button>
                </div>
              </div>
            </Modal>
          </div>
          <div className="block lg:hidden w-full">
            {filteredBoitiersList.map((boitier, index) => (
              <div key={boitier.id} className="mb-4 w-full border border-bleuSTB shadow-md rounded-lg">
                <div className="p-4 flex justify-between items-center cursor-pointer">
                  <div
                    className="flex items-center space-x-4"
                    onClick={() => navigateToDetails(boitier)}
                  >
                    <span className="text-sm md:text-2xl text-bleuSTB font-bold mr-16">{boitier.name}</span>
                    <span className="text-xs md:text-base text-end">Client: {boitier.clientName}</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(index);
                    }}
                  >
                    {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {expandedIndex === index && (
                  <div className="p-4 border-t border-gray-200">
                    <div className="flex flex-col space-y-4">
                      <div>
                        <span className="font-bold text-base md:text-xl text-bleuSTB">Date de mise en service:</span> <span className="text-base ml-1">{moment(boitier.dateOfService).format("DD/MM/YYYY")}</span>
                      </div>
                      <div>
                        <span className="font-bold text-base md:text-xl text-bleuSTB">Localisation :</span> <span className="text-base ml-1">{boitier.location}</span>
                      </div>
                      <div>
                        <span className="font-bold text-base md:text-xl text-bleuSTB">Alertes:</span>
                        <span className={`text-base ml-1 ${
                          boitier.alertCount > 0
                            ? "bg-red-200 text-red-700"
                            : "bg-green-200 text-green-800"
                        }`}>
                          {boitier.alertCount > 0
                            ? `${boitier.alertCount} dépassement(s)`
                            : "Pas de dépassement"}
                        </span>
                      </div>
                      {admin && (
                      <div className="flex justify-end">
                        <button
                          className="bg-orangeALF text-white text-xs px-4 py-2 rounded-lg hover:bg-orange-500"
                          onClick={() => openModal(boitier)}
                        >
                          Supprimer
                        </button>
                      </div>)}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}    
import React, { useState, useRef, useEffect , useCallback } from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from 'axios';
import { API_URL } from "../constants";
import { useLocation } from 'react-router-dom';
import  { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fr } from 'date-fns/locale';
import moment from 'moment';
import 'moment/locale/fr';
import differenceInDays from 'date-fns/differenceInDays';
import logoAiga from "./logoAiga.png";
import { addDays } from "date-fns";
import MotorNameEditor from './MotorNameEditor';
import MotorInformation from './MotorInformation';
import EmailFrequencyComponent from './EmailFrequencyComponent';
import ExporterPDFExcel from './ExporterPDFExcel';
import ChartsWrapper from './ChartsWrapper';
import FiltrageParametres from './FiltrageParametres';
import BoitierSettings from './BoitierSettings';
import AlertSettings from './AlertSettings';

registerLocale('fr', fr);

export default function DetailsCompteur(props) {
  const user = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")): props.user;
  const location = useLocation();
 const [motor, setMotor] = useState(location.state?.boitier || {});
  const [startDate, setStartDate] = useState(addDays(new Date(),0));
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState('day');
  const [availableGranularities, setAvailableGranularities] = useState(['day']);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [debitSeuil, setDebitSeuil] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const chartContainerRef = useRef();
  const secondChartContainerRef = useRef();
  const [impulseVolume, setImpulseVolume] = useState('');
  const [unit, setUnit] = useState('litre');
  const [unitConsumption, setUnitConsumption] = useState('litres');
  const [etalonnageName, setEtalonnageName] = useState('');
  const [etalonnageHistory, setEtalonnageHistory] = useState([]);
  const [creatingEtalonnage, setCreatingEtalonnage] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEtalonnage, setSelectedEtalonnage] = useState(null);
  const [boitier, setBoitier] = useState(null);
  const [consumptions, setConsumptions] = useState({});

 // Fonction pour gérer la demande de suppression d'un étalonnage
 const handleDeleteRequest = (etalonnage) => {
  setSelectedEtalonnage(etalonnage); // Enregistre l'étalonnage sélectionné
  setShowModal(true); // Affiche la modale
};

// Fonction pour supprimer un étalonnage après confirmation
const handleDeleteConfirm = async () => {
  if (selectedEtalonnage) {
    await handleDeleteEtalonnage(selectedEtalonnage._id); // Appelle la fonction de suppression
    setShowModal(false); // Ferme la modale après la suppression
  }
};

const handleUnitChange = (e) => {
  setUnit(e.target.value); // Met à jour l'unité en fonction de la sélection
};

const handleUnitConsumptionChange = (event) => {
  setUnitConsumption(event.target.value);
};

// Fonction pour capturer le changement du nom d'étalonnage
const handleEtalonnageNameChange = (e) => {
  setEtalonnageName(e.target.value);
};

// Fonction pour capturer le changement du volume par impulsion
const handleVolumeChange = (e) => {
  setImpulseVolume(e.target.value);
};

useEffect(() => {
  if (confirmationMessage) {
    const timer = setTimeout(() => {
      setConfirmationMessage('');
    }, 3000); // 3000 millisecondes = 3 secondes

    // Nettoyage de l'effet pour éviter des mises à jour d'état après le démontage
    return () => clearTimeout(timer);
  }
}, [confirmationMessage]);

const handleCreateEtalonnage = async () => {
  if (!impulseVolume || isNaN(parseFloat(impulseVolume))) {
    console.error('Volume par impulsion invalide.');
    return;
  }

    // Requête POST pour créer un nouvel étalonnage
    const response = await axios.post(`${API_URL}/boitier/etalonnage`, {
      nom: etalonnageName,  // Nom attribué par l'utilisateur
      valeur: parseFloat(impulseVolume)  // Volume par impulsion
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    const id_etalonnage = response.data.data._id;

    // Associer l'étalonnage au boîtier
    await axios.put(`${API_URL}/boitier/setetalonnage`, {
      id_boitier: motor.id,
      id_etalonnage: id_etalonnage
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    // Spécifier la date de début de l'étalonnage
    const dateDebut = moment().format('YYYY-MM-DD');  // Date actuelle
    await axios.put(`${API_URL}/boitier/debutetalonnage`, {
      id_etalonnage: id_etalonnage,
      dateDebut: dateDebut
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Étalonnage créé et associé avec succès.');
    setConfirmationMessage('Étalonnage créé avec succès !');
    setCreatingEtalonnage(false);

    try {
      // Requête pour récupérer la liste des boîtiers de l'utilisateur
      const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      });
  
      const boitier = response.data.data.find(b => b._id === motor.id);
      if (!boitier) {
        console.error('Boîtier non trouvé.');
        return;
      }
  
      const etalonnageIds = boitier.id_etalonnage;
      const etalonnage = etalonnageIds[etalonnageIds.length - 2];
      handleEndEtalonnage(etalonnage);

    } catch (error) {
      console.error("Erreur lors de la récupération de l'étalonnage :", error);
    }
};


const handleEndEtalonnage = async (id_etalonnage) => {
  try {
    const dateFin = moment().format('YYYY-MM-DD');  // Date actuelle

    await axios.put(`${API_URL}/boitier/finetalonnage`, {
      id_etalonnage: id_etalonnage,
      dateFin: dateFin
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Date de fin de l\'étalonnage mise à jour avec succès.');
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la date de fin:', error);
  }
};

const fetchEtalonnageHistory = async () => { 
  try {
    // Requête pour récupérer la liste des boîtiers de l'utilisateur
    const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    const boitier = response.data.data.find(b => b._id === motor.id);
    if (!boitier) {
      console.error('Boîtier non trouvé.');
      return;
    }

    const etalonnageIds = boitier.id_etalonnage;  // Tableau d'ID des étalonnages associés au boîtier

    // Requête pour récupérer tous les étalonnages
    const etalonnageResponse = await axios.get(`${API_URL}/boitier/listetalonnage`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    // Filtrer les étalonnages basés sur les ID récupérés
    const etalonnages = etalonnageResponse.data.data.filter(etalonnage => 
      etalonnageIds.includes(etalonnage._id)
    );

    setEtalonnageHistory(etalonnages);  // Stocker l'historique dans un état local pour l'afficher

    console.log('Historique des étalonnages récupéré avec succès.');
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'historique des étalonnages:', error);
  }
};

// Fonction pour supprimer un étalonnage
const handleDeleteEtalonnage = async (id_etalonnage) => {
  try {
    const response = await axios.delete(`${API_URL}/boitier/deletalonnage`, {
      data: { id: id_etalonnage },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    if (response.status === 204) {
      // Mise à jour de l'historique après suppression
      setEtalonnageHistory(prevHistory => 
        prevHistory.filter(etalonnage => etalonnage._id !== id_etalonnage)
      );
      console.log('Étalonnage supprimé avec succès.');
    } else {
      console.error('Erreur lors de la suppression : Réponse inattendue.');
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'étalonnage:', error);
  }
};

useEffect(() => {
  const fetchBoitierData = async () => {
    try {
      const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      });

      // Trouver le boîtier correspondant
      const boitierData = response.data.data.find(b => b._id === motor.id);
      
      // Mettre à jour l'état du boîtier
      if (boitierData) {
        setBoitier(boitierData);
      } else {
        console.error("Boîtier non trouvé");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du boîtier :", error);
    }
  };

  fetchBoitierData();
}, [user.userId, user.token, motor.id]);


const fetchImpulseVolume = useCallback(async () => {
  try {
    // Requête pour récupérer la liste des boîtiers de l'utilisateur
    const responseBoitiers = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      },
    });

    // Rechercher le boîtier spécifique avec son _id
    const boitier = responseBoitiers.data.data.find(b => b._id === motor.id);
    if (boitier && boitier.id_etalonnage) {
      // Récupérer la liste des étalonnages
      const responseEtalonnages = await axios.get(`${API_URL}/boitier/listetalonnage`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
      });

      // Filtrer les étalonnages qui correspondent aux IDs dans boitier.id_etalonnage
      const etalonnages = responseEtalonnages.data.data.filter(etalonnage => 
        boitier.id_etalonnage.includes(etalonnage._id)
      );

      // Vérifier si des étalonnages sont disponibles pour ce boîtier
      if (etalonnages.length > 0) {
        const latestEtalonnage = etalonnages[etalonnages.length - 1];  // Récupérer le dernier étalonnage
        console.log(latestEtalonnage);

        if (latestEtalonnage && latestEtalonnage.valeur) {
          setImpulseVolume(latestEtalonnage.valeur);
          setDebitSeuil(Math.round((boitier.depassement * (1/latestEtalonnage.valeur)).toFixed(0)));
        } else {
          console.error('Étalonnage introuvable ou sans valeur.');
        }
      } else {
        console.error('Aucun étalonnage trouvé pour ce boîtier.');
      }
    } else {
      console.error('Boîtier ou id_etalonnage introuvable.');
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'étalonnage :", error);
  }
}, [motor.id, user.userId, user.token]);


useEffect(() => {
  fetchImpulseVolume();  // Appel de la fonction dans le useEffect
}, [fetchImpulseVolume]);
  
  
  // Utilisation dans un useEffect pour récupérer les données lors de l'ouverture de la page
  useEffect(() => { 
    fetchImpulseVolume();
  }, [motor.id, user.id, user.token, fetchImpulseVolume]); 
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Mettre à jour si la largeur d'écran est inférieure à 768px (taille mobile)
    };

    // Ajouter un écouteur d'événement pour le redimensionnement
    window.addEventListener('resize', handleResize);

    // Appeler handleResize au montage du composant pour obtenir la taille actuelle
    handleResize();

    // Nettoyer l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const daysDiff = differenceInDays(endDate, startDate) + 1;

    let granularities = [];

    if (daysDiff < 2) {
      granularities.push('day');
    }

    if (daysDiff >= 7) {
      granularities.push('week');
    }

    const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    if (monthsDiff >= 1) {
      granularities.push('month');
    }

    if (daysDiff >= 365) {
      granularities.push('year');
    }

    setAvailableGranularities(granularities);

    if (!granularities.includes(granularity)) {
      setGranularity(granularities[0]);
    }
  }, [startDate, endDate, granularity]);

  useEffect(() => {
    // Generate hourly data for the second chart
    const generateHourlyData = () => {
      const hourlyGeneratedData = [];
      for (let hour = 0; hour < 24; hour++) {
        hourlyGeneratedData.push({
          name: `${String(hour).padStart(2, '0')}:00`,
          Debit: Math.floor(Math.random() * 50) + 10
        });
      }
    };
    generateHourlyData();
  }, []);

  // Fonction pour gérer l'envoi du seuil de dépassement
const handleSubmitSeuil = async () => {
  try {


    if (isNaN(impulseVolume) || impulseVolume <= 0) {
      console.error("Volume par impulsion invalide:", impulseVolume);
      return;
    }

    // Convertir le seuil en litres en nombre d'impulsions
    const seuil = 1/impulseVolume
    const seuilImpulsions = Math.round(debitSeuil / seuil);

    // Envoyer la requête PUT avec l'ID du boîtier et le seuil d'impulsions
    const response = await axios.put(`${API_URL}/boitier/depassement`, {
      id_boitier: motor.id,  // Assurez-vous que l'ID du boîtier est correct
      depassement: seuilImpulsions
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Seuil de dépassement mis à jour avec succès', response.data);

  } catch (error) {
    console.error('Erreur lors de la mise à jour du seuil de dépassement', error);
  }
};

const calculateTotalConsumptionForDay = useCallback(async (dateDepassement) => {
  try {
    const startDate = moment(dateDepassement).subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    const endDate = moment(dateDepassement).add(1, 'days').startOf('day').format('YYYY-MM-DD');

    const response = await axios.get(`${API_URL}/?d1=${startDate}&d2=${endDate}&id=${motor.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      },
    });

    const trames = response.data.data;
    let totalConsumption = 0;

    trames.forEach((trame) => {
      const volumeInLitres = trame.impulsion * (1 / impulseVolume);
      totalConsumption += volumeInLitres;
    });

    const convertedConsumption = unitConsumption === 'm3' ? totalConsumption / 1000 : totalConsumption;
    return convertedConsumption.toFixed(0);
  } catch (error) {
    console.error('Erreur lors de la récupération des trames :', error);
    return 0;
  }
}, [motor.id, user.token, impulseVolume, unitConsumption]); // Ajouter les dépendances appropriées

// Utiliser useEffect pour calculer la consommation dès que les dates de dépassement changent
useEffect(() => {
  const fetchConsumptions = async () => {
    const consumptionData = {};
    for (const date of boitier.dateDepassement || []) {
      const consumption = await calculateTotalConsumptionForDay(date);
      consumptionData[date] = consumption;
    }
    setConsumptions(consumptionData); // Mettre à jour l'état avec les consommations calculées
  };

  if (boitier && boitier.dateDepassement) {
    fetchConsumptions();
  }
}, [boitier, calculateTotalConsumptionForDay]); // Ajouter calculateTotalConsumptionForDay ici également


const fetchData = useCallback(async (debut, fin) => {
  try {
    moment.locale('fr-FR');
    let newStartDate = moment(debut).subtract(1, 'day').format('YYYY-MM-DD');
    const newEndDate = moment(fin).add(1, 'day').format('YYYY-MM-DD');

    const fetchTrames = async (startDate, endDate) => {
      const response = await axios.get(`${API_URL}/?d1=${startDate}&d2=${endDate}&id=${motor.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
      });
      return response.data.data;
    };

    let responseData = await fetchTrames(newStartDate, newEndDate);

    if (responseData.length !== 0) {
      let lastAvailableTrame = null;
      let previousDate = moment(debut).subtract(1, 'day');

      while (!lastAvailableTrame && previousDate.isAfter(moment(debut).subtract(1, 'year'))) {
        const previousStartDate = previousDate.subtract(1, 'day').format('YYYY-MM-DD');
        const previousEndDate = previousDate.add(1, 'day').format('YYYY-MM-DD');

        const currentData = await fetchTrames(previousStartDate, previousEndDate);

        if (currentData.length > 0) {
          lastAvailableTrame = currentData[currentData.length - 1];
          responseData = [...currentData, ...responseData];
        }
        previousDate.subtract(1, 'day');
      }
    }

    responseData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    if (!impulseVolume || isNaN(impulseVolume)) {
      console.error("Volume par impulsion invalide:", impulseVolume);
      return;
    }

    // Calculer la moyenne des 5 premières impulsions
    const initialImpulsions = responseData.slice(0, 5).map(item => item.impulsion);
    const averageImpulse = initialImpulsions.length > 0 ? initialImpulsions.reduce((acc, val) => acc + val, 0) / initialImpulsions.length : 0;

    const threshold = averageImpulse * 1.2;  // 20 % de la moyenne

    const validData = responseData.filter((item, index) => {
      // Ignorer les trames si elles dépassent 20 % de la moyenne
      return item.impulsion <= threshold || index < 5;  // Toujours garder les 5 premières impulsions
    });

    // Assurer que validData est utilisé pour le reste des traitements
    validData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    const months = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    const formatNumber = (number) => {
      return number.toFixed(2);
    };

    const convertVolume = (volume) => {
      return unit === 'm3' ? volume / 1000 : volume;
    };

    const convertConsumptionVolume = (volume) => {
      return unitConsumption === 'm3' ? volume / 1000 : volume;
    };

    const formatTime = timestamp => {
      if (granularity === 'day') {
        return moment(timestamp).format('HH:mm');
      } else if (granularity === 'month') {
        return `Semaine ${moment(timestamp).isoWeek()}`;
      } else if (granularity === 'year') {
        const monthIndex = moment(timestamp).month();
        return `${months[monthIndex]}`;
      } else {
        return moment(timestamp).format('DD/MM/YYYY');
      }
    };

    const aggregateData = (data, granularity) => {
      const groupedData = data.reduce((acc, item, index, array) => {
        let key;
        if (granularity === 'week') {
          key = moment(item.timestamp).startOf('day').format('DD/MM/YYYY');
        } else if (granularity === 'month') {
          key = `Semaine ${moment(item.timestamp).isoWeek()}`;
        } else if (granularity === 'year') {
          const monthIndex = moment(item.timestamp).month();
          key = `${months[monthIndex]}`;
        } else {
          key = formatTime(item.timestamp);
        }

        if (!acc[key]) {
          acc[key] = { name: key, Consommation: 0, Debit: 0, count: 0 };
        }

        const volume = item.impulsion * (1 / impulseVolume);
        acc[key].Consommation += convertConsumptionVolume(volume);

        let hoursDifference = 1 / 6;  // Par défaut, 10 minutes
        if (index > 0) {
          const previousTimestamp = new Date(array[index - 1].timestamp);
          const currentTimestamp = new Date(item.timestamp);
          const timeDifferenceInMs = currentTimestamp - previousTimestamp;
          hoursDifference = timeDifferenceInMs / (1000 * 60 * 60);
        }

        acc[key].Debit += convertVolume(volume) / hoursDifference;
        acc[key].count += 1;

        return acc;
      }, {});

      return Object.values(groupedData).map(item => ({
        ...item,
        Debit: item.count > 0 ? parseFloat(formatNumber(item.Debit / item.count)) : 0,
        Consommation: parseFloat(formatNumber(item.Consommation))
      }));
    };

    if (granularity === 'day') {
      let accumulatedConsumption = 0;
      let lastTimestampFromPreviousDay = validData.length > 0 ? validData[0].timestamp : null;

      const dataConsumptionForCharts = validData.map((item, index, array) => {
        const isCurrentDay = moment(item.timestamp).isSame(debut, 'day');

        if (!isCurrentDay) {
          lastTimestampFromPreviousDay = item.timestamp;
          return null;
        }

        const volumeInLitres = item.impulsion * (1 / impulseVolume);
        const volumeConsumption = unitConsumption === 'm3' ? volumeInLitres / 1000 : volumeInLitres;

        accumulatedConsumption += volumeConsumption;

        return {
          name: moment(item.timestamp).format('HH:mm'),
          Consommation: parseFloat(formatNumber(accumulatedConsumption)),
        };
      }).filter(item => item !== null);

      const dataFlowForCharts = validData.map((item, index, array) => {
        const isCurrentDay = moment(item.timestamp).isSame(debut, 'day');
      
        if (!isCurrentDay) {
          return null;
        }
      
        const volumeInLitres = item.impulsion * (1 / impulseVolume);
        const volumeFlow = unit === 'm3' ? volumeInLitres / 1000 : volumeInLitres;
      
        let hoursDifference = 1 / 6;
        if (index > 0 || lastTimestampFromPreviousDay) {
          const previousTimestamp = index === 0 ? new Date(lastTimestampFromPreviousDay) : new Date(array[index - 1].timestamp);
          const currentTimestamp = new Date(item.timestamp);
          const timeDifferenceInMs = currentTimestamp - previousTimestamp;

          hoursDifference = timeDifferenceInMs > 0 ? timeDifferenceInMs / (1000 * 60 * 60) : 0.17;
        }
      
        return {
          name: moment(item.timestamp).format('HH:mm'),
          Debit: parseFloat(formatNumber(volumeFlow / hoursDifference)),
        };
      }).filter(item => item !== null);

      setData2(dataConsumptionForCharts);
      setData3(dataFlowForCharts);
    } else {
      const aggregatedData = aggregateData(validData, granularity);
      setData2(aggregatedData);
      setData3(aggregatedData);
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
  }
}, [motor.id, user.token, granularity, impulseVolume, unit, unitConsumption]); // Dépendances de useCallback

  // Fonction appelée lors du clic sur le bouton Rechercher
  const handleSearchClick = useCallback(() => {
    const debut = moment(startDate).format('YYYY-MM-DD');
    const fin = moment(endDate).format('YYYY-MM-DD');
    fetchData(debut, fin);
  }, [fetchData, startDate, endDate]);  // Dépendances de useCallback
  
  // useEffect pour récupérer les données lors de l'ouverture de la page
  useEffect(() => {
    handleSearchClick();
  }, [handleSearchClick]);
  
  const getChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Consommation du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Consommation de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Consommation du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Consommation de l'année ${year} - ${motor.name}`;
        default:
            return `Consommation - ${motor.name}`;
    }
  };

  const getChartTitlePDF = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Rapport du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Rapport de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Rapport du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Rapport de l'année ${year} - ${motor.name}`;
        default:
            return `Rapport - ${motor.name}`;
    }
  };

  const getChartTitleExcel = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Tableur du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Tableur de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Tableur du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Tableur de l'année ${year} - ${motor.name}`;
        default:
            return `Tableur - ${motor.name}`;
    }
  };

  const getSecondChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Débit du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Débit de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Débit du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Débit de l'année ${year} - ${motor.name}`;
        default:
            return `Débit - ${motor.name}`;
    }
};

return (
  <div className="min-h-screen flex flex-col bg-gray-100 text-gray-900">
    <Header user={user} />
    <div className="flex-grow flex items-center justify-center py-12">
        <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
        <MotorNameEditor motor={motor} setMotor={setMotor} user={user} API_URL={API_URL} />
        <MotorInformation motor={motor} user={user} />
        <EmailFrequencyComponent user={user} motor={motor} />
        <BoitierSettings user={user} impulseVolume={impulseVolume} etalonnageName={etalonnageName} confirmationMessage={confirmationMessage} creatingEtalonnage={creatingEtalonnage} etalonnageHistory={etalonnageHistory} showModal={showModal} selectedEtalonnage={selectedEtalonnage} handleVolumeChange={handleVolumeChange} handleEtalonnageNameChange={handleEtalonnageNameChange} handleCreateEtalonnage={handleCreateEtalonnage} fetchEtalonnageHistory={fetchEtalonnageHistory} handleDeleteRequest={handleDeleteRequest} handleDeleteConfirm={handleDeleteConfirm} setCreatingEtalonnage={setCreatingEtalonnage} setEtalonnageName={setEtalonnageName} setImpulseVolume={setImpulseVolume} setEtalonnageHistory={setEtalonnageHistory} setShowModal={setShowModal} />
        <AlertSettings debitSeuil={debitSeuil} setDebitSeuil={setDebitSeuil} handleSubmitSeuil={handleSubmitSeuil} boitier={boitier} consumptions={consumptions} />
        <FiltrageParametres startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} granularity={granularity} setGranularity={setGranularity} availableGranularities={availableGranularities} handleSearchClick={handleSearchClick} />
        <ChartsWrapper data2 = {data2} data3 = {data3} unitConsumption = {unitConsumption} chartContainerRef = {chartContainerRef} secondChartContainerRef = {secondChartContainerRef} unit = {unit} handleUnitConsumptionChange = {handleUnitConsumptionChange} handleUnitChange = {handleUnitChange} getChartTitle = {getChartTitle} getSecondChartTitle = {getSecondChartTitle} isMobile = {isMobile} />
        <ExporterPDFExcel data2 = {data2} data3 = {data3} unitConsumption = {unitConsumption} unit = {unit}  chartContainerRef = {chartContainerRef} secondChartContainerRef = {secondChartContainerRef} logoAiga = {logoAiga} debitSeuil = {debitSeuil} boitier = {boitier} granularity = {granularity} getChartTitlePDF = {getChartTitlePDF} getChartTitleExcel = {getChartTitleExcel} calculateTotalConsumptionForDay = {calculateTotalConsumptionForDay} />
      </div>
    </div>
    <Footer />
  </div>
);
}
import React from 'react';
import moment from 'moment';

const BoitierSettings = ({
  user,
  impulseVolume,
  etalonnageName,
  confirmationMessage,
  creatingEtalonnage,
  etalonnageHistory,
  showModal,
  selectedEtalonnage,
  handleVolumeChange,
  handleEtalonnageNameChange,
  handleCreateEtalonnage,
  fetchEtalonnageHistory,
  handleDeleteRequest,
  handleDeleteConfirm,
  setCreatingEtalonnage,
  setEtalonnageName,
  setImpulseVolume,
  setEtalonnageHistory,
  setShowModal,
}) => {
  if (!user.admin) return null;

  return (
    <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres du Boîtier</h2>
      
      {console.log(impulseVolume)}
      {impulseVolume !== "" && (
        <div className="mb-6">
          <label htmlFor="impulseVolume" className="block text-lg font-medium text-gray-700 mb-2">
            Impulsions par Litre :
          </label>
          <input
            id="impulseVolume"
            type="number"
            step="0.000001"
            className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Litres par impulsion"
            value={impulseVolume || ''}
            onChange={handleVolumeChange}
            disabled
          />
        </div>
      )}

      <button
        className="bg-blue-500 text-white max-sm:flex max-sm:justify-center mb-6 mr-4 px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300"
        onClick={() => setCreatingEtalonnage(true)}
      >
        Créer un nouvel étalonnage
      </button>

      {confirmationMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-4 rounded relative mb-6" role="alert">
          <span className="block sm:inline">{confirmationMessage}</span>
        </div>
      )}

      {creatingEtalonnage && (
        <>
          <div className="mt-6 mb-6">
            <label htmlFor="etalonnageName" className="block text-lg font-medium text-gray-700 mb-2">
              Nom de l'Étalonnage :
            </label>
            <input
              id="etalonnageName"
              type="text"
              className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Nom de l'étalonnage"
              value={etalonnageName || ''}
              onChange={handleEtalonnageNameChange}
            />
          </div>

          <div className="mb-6">
            <label htmlFor="impulseVolume" className="block text-lg font-medium text-gray-700 mb-2">
              Impulsions par Litre :
            </label>
            <input
              id="impulseVolume"
              type="number"
              step="0.000001"
              className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Litres par impulsion"
              onChange={handleVolumeChange}
            />
          </div>

          <div className="flex justify-center space-x-4 mt-4">
            <button
              className="bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-green-600 transition-all duration-300"
              onClick={handleCreateEtalonnage}
            >
              Valider
            </button>
            <button
              className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-600 transition-all duration-300"
              onClick={() => {
                setCreatingEtalonnage(false);
                setEtalonnageName(''); // Réinitialise le nom de l'étalonnage
                setImpulseVolume(''); // Réinitialise le volume
              }}
            >
              Annuler
            </button>
          </div>
        </>
      )}

      <button
        className="bg-gray-500 text-white px-6 py-3 rounded-lg shadow-lg max-sm:flex max-sm:justify-center mt-6 hover:bg-gray-600 transition-all duration-300"
        onClick={fetchEtalonnageHistory}
      >
        Voir l'historique des étalonnages
      </button>

      {etalonnageHistory.length > 0 && (
        <div className="mt-8 p-6 border border-gray-300 rounded-lg bg-white shadow-sm">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Historique des Étalonnages :</h3>

          <button
            className="flex items-center justify-center bg-blue-500 text-white font-medium px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            onClick={() => setEtalonnageHistory([])}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            Fermer l'historique
          </button>

          <ul className="space-y-4 mt-4">
            {etalonnageHistory.map((etalonnage) => (
              <li key={etalonnage._id} className="p-4 bg-gray-100 rounded-lg border border-gray-300">
                <div className="flex justify-between items-center">
                  <strong className="text-lg text-gray-900">{etalonnage.nom}</strong>
                  <span className="text-sm text-gray-600">{moment(etalonnage.dateDebut).format('LL')}</span>
                </div>
                <p className="text-gray-700 mt-1">Valeur : <span className="font-semibold">{etalonnage.valeur}</span></p>
                <p className="text-gray-500 text-sm">Fin : {etalonnage.dateFin ? moment(etalonnage.dateFin).format('LL') : 'En cours'}</p>
                <button
                  className="mt-2 text-red-500 hover:underline"
                  onClick={() => handleDeleteRequest(etalonnage)}
                >
                  Supprimer
                </button>
              </li>
            ))}
          </ul>
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4">Confirmer la suppression</h3>
                <p>Êtes-vous sûr de vouloir supprimer l'étalonnage <strong>{selectedEtalonnage.nom}</strong> ?</p>
                <div className="mt-6 flex justify-end">
                  <button
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                    onClick={() => setShowModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={handleDeleteConfirm}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BoitierSettings;

import React from 'react';
import AddClientLocation from './AddClientLocation';

const MotorInformation = ({ motor, user }) => {
  return (
    <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-center text-xl lg:text-2xl font-semibold mb-6 text-gray-800">
        Informations du Compteur
      </h2>
      <p className="text-lg font-medium text-gray-700 mb-4">
        Client: <span className="font-bold text-blue-600">{motor.clientName || "Pas encore de client"}</span>
      </p>
      <p className="text-lg font-medium text-gray-700">
        Localisation: <span className="font-bold text-blue-600">{motor.location || "Pas encore de localisation"}</span>
      </p>
      {!motor.clientName || !motor.location ? (
        <AddClientLocation
          motor={motor}
          token={user.token}
        />
      ) : (
        <p>Client et localisation déjà définis.</p>
      )}
    </div>
  );
};

export default MotorInformation;
import React from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LineChart, Line } from 'recharts';

const ChartsWrapper = ({ data2, data3, unitConsumption, chartContainerRef,secondChartContainerRef, unit, handleUnitConsumptionChange, handleUnitChange, getChartTitle, getSecondChartTitle, isMobile }) => {

  return (
    <div className="mt-10 flex flex-col items-center">
      {data2.length > 0 ? (
        <>
          {/* Graphique de consommation */}
          <div className="w-full max-w-3xl" ref={chartContainerRef} style={{ backgroundColor: '#000000', padding: '20px', borderRadius: '10px' }}>
            <h3 className="text-xl lg:text-2xl font-medium text-center mb-4 text-white">{getChartTitle()}</h3>
            <div className="flex justify-start items-center mb-4">
              <label className="text-gray-300 font-medium mr-3">Unité de Consommation :</label>
              <div className="relative">
                <select
                  value={unitConsumption}
                  onChange={handleUnitConsumptionChange}
                  className="appearance-none bg-gray-800 border border-gray-500 text-gray-300 py-2 px-4 pr-10 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                >
                  <option value="litres">Litres</option>
                  <option value="m3">m³</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full h-auto">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data2} margin={{ top: 35, right: 30, left: 20, bottom: 5 }} style={{ backgroundColor: '#000000' }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="name" tick={{ fill: '#ffffff' }} />
                  <YAxis
                    label={{
                      value: unitConsumption === 'm3' ? 'm³' : 'Litres',
                      angle: -90,
                      position: 'insideLeft',
                      fill: '#ffffff',
                    }}
                    tick={{ fill: '#ffffff' }}
                  />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#ffffff', border: 'none' }}
                    itemStyle={{ color: '#333' }}
                    formatter={(value, name) => {
                      if (name === 'Consommation') {
                        return [`${value} ${unitConsumption === 'm3' ? 'm³' : 'L'}`, name];
                      }
                      return value;
                    }}
                  />
                  <Legend wrapperStyle={{ color: '#ffffff' }} />
                  <Bar dataKey="Consommation" fill="rgba(30, 144, 255, 0.8)" stroke="rgba(0, 0, 139, 1)" strokeWidth={3} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Graphique de débit */}
          <div className="w-full max-w-3xl mt-10" ref={secondChartContainerRef}>
            <h3 className="text-xl lg:text-2xl font-medium text-center mb-4">{getSecondChartTitle()}</h3>
            <div className="flex justify-start items-center mb-4">
              <label className="text-gray-600 font-medium mr-3">Unité :</label>
              <div className="relative">
                <select
                  value={unit}
                  onChange={handleUnitChange}
                  className="appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-10 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
                >
                  <option value="litres">Litre/h</option>
                  <option value="m3">m³/h</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full h-auto">
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data3} margin={{ top: 35, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={isMobile ? false : undefined} />
                  <YAxis label={{ value: unit === 'm3' ? 'm³/h' : 'Litre/h', angle: -90, position: 'insideLeft' }} />
                  <Tooltip formatter={(value, name) => {
                    if (name === 'Debit') {
                      return [`${value} ${unit === 'm3' ? 'm³/h' : 'Litre/h'}`, name];
                    }
                    return value;
                  }} />
                  <Legend />
                  <Line type="monotone" dataKey="Debit" stroke="#82ca9d" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      ) : (
        <p className="text-center text-gray-600">Aucune donnée à afficher</p>
      )}
    </div>
  );
};

export default ChartsWrapper;

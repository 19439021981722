import React from 'react';

const AlertSettings = ({
  debitSeuil,
  setDebitSeuil,
  handleSubmitSeuil,
  boitier,
  consumptions,
}) => {
  return (
    <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Alerte de Dépassement</h2>
      
      <div className="mb-6">
        <label htmlFor="debitSeuil" className="block text-lg font-medium text-gray-700 mb-2">
          Définir Volume de Dépassement par Jour (L) :
        </label>
        <input
          id="debitSeuil"
          type="number"
          className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Litres par jour"
          value={debitSeuil}
          onChange={(e) => setDebitSeuil(e.target.value)}
        />
      </div>

      <button
        className="bg-blue-500 text-white px-6 py-3 rounded-lg"
        onClick={handleSubmitSeuil}
      >
        Enregistrer
      </button>

      <div className="mt-8 bg-white border border-gray-300 shadow-md rounded-lg p-6">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800 border-b pb-2">Historique des Dépassements</h3>

        {!boitier ? (
          <p className="text-gray-500">Chargement des données...</p>
        ) : (
          <div>
            {boitier.dateDepassement && boitier.dateDepassement.length === 0 ? (
              <p className="text-gray-500">Aucun dépassement n'a été enregistré pour l'instant.</p>
            ) : (
              <div>
                <p className="text-gray-700 mb-4">Nombre total de dépassements : {boitier.dateDepassement.length}</p>
                <ul className="list-disc pl-5 space-y-2">
                  {boitier.dateDepassement.map((date, index) => (
                    <li key={index} className="text-gray-700 bg-gray-100 p-2 rounded-md shadow-sm">
                      <p>Dépassement le : {new Date(date).toLocaleDateString()}</p>
                      <p>
                        Consommation totale de la journée :{' '}
                        <span className="font-bold text-blue-600">
                          {consumptions[date] ? `${consumptions[date]} L` : 'Calcul en cours...'}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertSettings;

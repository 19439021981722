import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';

const MotorNameEditor = ({ motor, setMotor, user, API_URL }) => {
  const [newMotorName, setNewMotorName] = useState(motor.name);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewMotorName(motor.name); // Réinitialiser si annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.post(`${API_URL}/boitier/nomboitier`, {
        id_boitier: motor.id,
        nom_boitier: newMotorName
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      console.log('Boîtier modifié:', response.data);

      // Mettre à jour le state et le localStorage avec l'ID spécifique du boîtier
      setMotor(prevMotor => ({ ...prevMotor, name: newMotorName }));
      localStorage.setItem(`boitierName_${motor.id}`, newMotorName);
      setIsEditing(false);
    } catch (error) {
      console.error('Erreur lors de la modification du boîtier:', error);
    }
  };

  // Récupérer le nom spécifique au boîtier à partir du localStorage
  useEffect(() => {
    const storedMotorName = localStorage.getItem(`boitierName_${motor.id}`);
    if (storedMotorName) {
      setNewMotorName(storedMotorName);  // Mettre à jour l'état avec le nom stocké pour ce boîtier
      setMotor(prevMotor => ({ ...prevMotor, name: storedMotorName }));
    } else {
      setNewMotorName(motor.name); // Revenir au nom du boîtier si rien n'est trouvé
    }
  }, [motor.id, motor.name, setMotor]); // Réagir aux changements de motor.id ou motor.name

  return (
    <h1 className="text-4xl font-semibold text-center mb-8">
      {isEditing ? (
        <div className="flex flex-col items-center justify-center space-y-4">
          <input
            type="text"
            value={newMotorName}
            onChange={(e) => setNewMotorName(e.target.value)}
            className="border border-gray-300 p-3 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none w-96"
            placeholder="Nom du moteur"
          />
          <div className="flex space-x-4">
            <button
              onClick={handleSaveClick}
              className="bg-gradient-to-r from-green-400 to-green-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-gradient-to-r from-red-400 to-red-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            >
              Annuler
            </button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center space-x-4">
          <span>{motor.name}</span>
          <button onClick={() => setIsEditing(true)} className="ml-2">
            <FaEdit className="text-black-500 hover:text-blue-700" />
          </button>
        </div>
      )}
    </h1>
  );
};

export default MotorNameEditor;

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import React from 'react';

const ExporterPDFExcel = ({ data2, data3, unitConsumption, unit, chartContainerRef, secondChartContainerRef, logoAiga, debitSeuil, boitier, granularity, getChartTitlePDF, getChartTitleExcel, calculateTotalConsumptionForDay }) => {

  const exportPDF = async () => {
    const chartElement1 = chartContainerRef.current;
    const chartElement2 = secondChartContainerRef.current;

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.width;
    let yPosition = 50;

    // PAGE 1 - Header et graphiques
    const logoUrl = logoAiga;
    pdf.addImage(logoUrl, 'PNG', 10, 10, 50, 20);

    pdf.setFontSize(20);
    pdf.setFont('Helvetica', 'bold');
    pdf.text('Rapport de Consommation', pageWidth / 2 + 6, 25, { align: 'center' });

    pdf.setFontSize(12);
    pdf.setFont('Helvetica', 'normal');
    const dateStr = new Date().toLocaleDateString('fr-FR');
    pdf.text(`Date : ${dateStr}`, pageWidth - 10, 25, { align: 'right' });

    // Ajouter les graphiques
    if (chartElement1) {
      const canvas1 = await html2canvas(chartElement1, { scale: 3 });
      const imgData1 = canvas1.toDataURL('image/png', 1.0);
      pdf.addImage(imgData1, 'PNG', 10, yPosition, pageWidth - 20, 100, undefined, 'FAST');
      pdf.setDrawColor(0, 0, 0);
      pdf.rect(10, yPosition + 2, pageWidth - 20, 100);
      yPosition += 110;
    }

    if (chartElement2) {
      const canvas2 = await html2canvas(chartElement2, { scale: 3 });
      const imgData2 = canvas2.toDataURL('image/png', 1.0);
      pdf.addImage(imgData2, 'PNG', 10, yPosition, pageWidth - 20, 100, undefined, 'FAST');
      pdf.setDrawColor(0, 0, 0);
      pdf.rect(10, yPosition + 2, pageWidth - 20, 100);
      yPosition += 110;
    }

    if (yPosition + 50 > pdf.internal.pageSize.height) {
      pdf.addPage();
      yPosition = 20;
    }

    // PAGE 2 - Informations et alertes
    pdf.setFontSize(16);
    pdf.setFont('Helvetica', 'bold');
    pdf.text('Informations sur le volume', 10, yPosition);
    yPosition += 20;

    pdf.setFontSize(14);
    pdf.setFont('Helvetica', 'normal');
    pdf.text(`Seuil alerte : ${debitSeuil} L/jour`, 10, yPosition);
    yPosition += 20;

    pdf.setLineWidth(0.5);
    pdf.line(10, yPosition, pageWidth - 10, yPosition);
    yPosition += 10;

    pdf.setFontSize(16);
    pdf.setFont('Helvetica', 'bold');
    pdf.text('Alertes', 10, yPosition);
    yPosition += 15;

    pdf.setFontSize(14);
    pdf.setFont('Helvetica', 'normal');

    if (boitier.dateDepassement && boitier.dateDepassement.length > 0) {
      const lineHeight = 10;
      for (const date of boitier.dateDepassement) {
        if (yPosition + lineHeight > pdf.internal.pageSize.height - 20) {
          pdf.addPage();
          yPosition = 20;
          pdf.setFontSize(16);
          pdf.setFont('Helvetica', 'bold');
          pdf.text('Alertes (suite)', 10, yPosition);
          yPosition += 15;
          pdf.setFontSize(14);
          pdf.setFont('Helvetica', 'normal');
        }
        const totalConsumption = await calculateTotalConsumptionForDay(date);
        pdf.text(`Date: ${new Date(date).toLocaleDateString()} - Volume dépassé: ${totalConsumption} L`, 10, yPosition);
        yPosition += lineHeight;
      }
    } else {
      pdf.text('Aucune alerte enregistrée.', 10, yPosition);
    }

    pdf.save(`${getChartTitlePDF()}.pdf`);
  };

  const exportExcel = () => {
    let firstColumnHeader;
    switch (granularity) {
      case 'day':
      case 'week':
        firstColumnHeader = 'Jour';
        break;
      case 'month':
        firstColumnHeader = 'Semaine';
        break;
      case 'year':
        firstColumnHeader = 'Mois';
        break;
      default:
        firstColumnHeader = 'Nom';
    }
  
    const sheetTitle = getChartTitleExcel();
  
    // Condition pour ajuster l'unité de consommation en fonction du choix de l'utilisateur
    const consommationUnit = unitConsumption === 'm3' ? 'Consommation (m³)' : 'Consommation (L)';
    
    // Condition pour ajuster l'unité de débit en fonction du choix de l'utilisateur
    const debitUnit = unit === 'm3' ? 'Débit (m³/h)' : 'Débit (L/h)';
  
    // Données de consommation avec unité dynamique
    const consommationData = [
      [sheetTitle],
      [],
      [firstColumnHeader, consommationUnit],
      ...data2.map(item => [item.name, item.Consommation])
    ];
  
    // Données de débit avec unité dynamique
    const debitData = [
      [sheetTitle],
      [],
      [firstColumnHeader, debitUnit],
      ...data3.map(item => [item.name, item.Debit])
    ];
  
    const workbook = XLSX.utils.book_new();
    const consommationSheet = XLSX.utils.aoa_to_sheet(consommationData);
    XLSX.utils.book_append_sheet(workbook, consommationSheet, 'Consommation');
    const debitSheet = XLSX.utils.aoa_to_sheet(debitData);
    XLSX.utils.book_append_sheet(workbook, debitSheet, 'Débit');
  
    XLSX.writeFile(workbook, `${getChartTitleExcel()}.xlsx`);
  };
  

  return (
    <div className="mt-4 flex justify-center space-x-4">
      <button
        onClick={exportPDF}
        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
      >
        Exporter en PDF
      </button>
      <button
        onClick={exportExcel}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
      >
        Exporter en Excel
      </button>
    </div>
  );
};

export default ExporterPDFExcel;

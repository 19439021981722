import React from 'react';
import DatePicker from 'react-datepicker'; // Assurez-vous d'avoir react-datepicker installé
import 'react-datepicker/dist/react-datepicker.css'; // Assurez-vous d'importer le style
import { fr } from 'date-fns/locale'; // Importation de la locale française

const FiltrageParametres = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  granularity,
  setGranularity,
  availableGranularities,
  handleSearchClick,
}) => {
  return (
    <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8">
      <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres de Filtrage</h2>
      <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-6">
        {/* Sélection de la date de début */}
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de début:</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              locale={fr}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {/* Sélection de la date de fin */}
          <div className="flex-1">
            <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de fin:</label>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              locale={fr}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        {/* Sélection de la granularité */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">Granularité:</label>
          <select
            value={granularity}
            onChange={e => setGranularity(e.target.value)}
            className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {availableGranularities.includes('day') && (
              <option value="day">Jour (10 min par point)</option>
            )}
            {availableGranularities.includes('week') && (
              <option value="week">Semaine (1 jour par point)</option>
            )}
            {availableGranularities.includes('month') && (
              <option value="month">Mois (1 semaine par point)</option>
            )}
            {availableGranularities.includes('year') && (
              <option value="year">Année (1 mois par point)</option>
            )}
          </select>
        </div>

        {/* Bouton de recherche */}
        <button
          onClick={handleSearchClick}
          className="bg-blue-600 text-white py-3 rounded-lg w-full hover:bg-blue-700 transition duration-300 font-semibold"
        >
          Rechercher
        </button>
      </div>
    </div>
  );
};

export default FiltrageParametres;

import React from 'react';
import SetDefaultEmailFrequency from './SetDefaultEmailFrequency';

const EmailFrequencyComponent = ({ user, motor }) => {
  return (
    <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">
        Fréquences des mails
      </h2>
      <div className="mb-6">
        <SetDefaultEmailFrequency token={user.token} motor={motor} />
      </div>
    </div>
  );
};

export default EmailFrequencyComponent;

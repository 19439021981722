import { useCallback, useEffect, useState } from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UserTable from "./UserTable";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import ChangePasswordForm from './ChangePasswordForm';
import CapteurList from './CapteurList';
import SearchCapteur from './SearchCapteur';
import axios from 'axios';
import { API_URL } from '../constants'; // Assurez-vous que API_URL est bien défini

export default function Admin(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

    const userId = user.userId;

  // Utilisateurs récupérés depuis l'API
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCapteur, setSelectedCapteur] = useState(null);
  const [selectedIdCompteur,setSelectedIdCompteur] = useState(null);
  const [showMoteurList, setShowMoteurList] = useState(true);
  const [capteurs, setCapteurs] = useState([]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/user/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
    }
  }, [user.token]); 

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Simuler une liste de capteurs
  const fetchCapteurs = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/boitier/listboitier?id=${userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setCapteurs(response.data.data); // Stocker les capteurs dans l'état
    } catch (error) {
      console.error("Erreur lors de la récupération des compteurs :", error);
    }
  }, [user.token , userId]);

  useEffect(() => {
    fetchCapteurs(); // Appel de l'API pour récupérer les capteurs
  }, [fetchCapteurs, userId]); 

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSelectedCapteur(null);
    setShowMoteurList(true);
  };

  const handleCapteurClick = (capteur) => {
    setSelectedCapteur(capteur.nom_boitier);
    setSelectedIdCompteur(capteur._id);
    setSearchTerm(capteur.nom_boitier);
    setShowMoteurList(false);
  };

  const filteredCapteurs = capteurs.filter((capteur) => {
    return (
      capteur.nom_boitier.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !capteur.id_user.some((userId) => userId === selectedUserId)
    );
  });

  const ajouterCompteurUtilisateur = async () => {
    try {
      const response = await axios.post(`${API_URL}/boitier/ajoutboitier`, {
        id_user: selectedUserId,
        id_boitier: selectedIdCompteur,
      }, {
        headers: {
          mode: "cors",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      });
      const json = response.data;
      console.log(json.data);
      window.location.reload();
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleAjouterCompteur = () => {
    ajouterCompteurUtilisateur();
  };


  const deleteUser = async (userId) => {
    try {
      const response = await axios.post(`${API_URL}/user/suppr`, 
        { id: userId }, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      if (response.status === 200) {
        fetchUsers();
      } else {
        console.error("Erreur lors de la suppression de l'utilisateur");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };
  

  const updateUser = (updatedUser) => {
    setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
  };

  const toggleAdmin = async (userId, isAdmin) => {
    try {
      const response = await axios.put(`${API_URL}/user/setadmin`, 
        {
          id: userId, 
          isAdmin: isAdmin,
        }, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      if (response.status === 200) {
        setUsers(users.map(user => 
          user._id === userId ? { ...user, admin: isAdmin } : user
        ));
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rôle admin :", error);
    }
  };
  
  

  useEffect(() => {
    if (selectedUserId) {
      const user = users.find(user => user._id === selectedUserId);
      setSelectedUserEmail(user.email);
    }
  }, [selectedUserId, users]);

  const handleEditClick = (userId, userEmail) => {
    setSelectedUserId(userId);
    setSelectedUserEmail(userEmail);
  };

  const handleCloseEditForm = () => {
    setIsEditing(false);
    setSelectedUserId(null);
    setSelectedUserEmail("");
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full w-full items-center justify-center p-4">
        <div className="border-4 border-bleuSTB rounded-2xl w-full text-xs sm:text-base sm:w-auto flex flex-col-reverse  lg:flex-row p-6">
          <div id="flex flex-col items-center">
            <h2 className="text-center text-bleuSTB mb-6 text-lg sm:text-xl xl:text-5xl font-bold">Gestion Utilisateurs</h2>
            {isEditing ? (
              <EditUserForm
                userId={selectedUserId}
                userEmail={selectedUserEmail}
                onClose={handleCloseEditForm}
                onUpdateUser={updateUser}
              />
            ) : (
              <>
                <UserTable
                  users={users}
                  deleteUser={deleteUser}
                  setSelectedUserId={setSelectedUserId}
                  setSelectedUserEmail={setSelectedUserEmail}
                  setAdminRole={(userId, isAdmin) => toggleAdmin(userId, isAdmin)} // Appel API pour admin
                  onEditUser={handleEditClick}
                />
              </>
            )}
          </div>
          <div className="flex flex-col p-8">
            <div className="flex flex-col border-4 border-bleuSTB rounded-2xl p-6 mb-4 ">
              <AddUserForm token={user.token} />
            </div>
            {selectedUserId ? (
              <div className="flex flex-col border-4 border-bleuSTB rounded-2xl p-4 text-base lg:text-2xl">
                <span className="text-center text-bleuSTB 3xl:text-3xl">{selectedUserEmail}</span>
                <EditUserForm id={selectedUserId} token={user.token} rafraichir={fetchUsers} />
                <ChangePasswordForm id={selectedUserId} token={user.token} />
                <>
                <SearchCapteur 
                searchTerm={searchTerm} 
                handleSearch={handleSearch} 
                handleAjouterCompteur={handleAjouterCompteur}
                selectedCapteur={selectedCapteur} 
                />

                  {showMoteurList && searchTerm !== "" && filteredCapteurs.length > 0 && (
                    <CapteurList
                      filteredCapteurs={filteredCapteurs}
                      handleCapteurClick={handleCapteurClick}
                    />
                  )}
                </>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
